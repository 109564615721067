<div class="nav">

    <div class="links">
        <a href="#home">Home</a>
        <a href="#about">About</a>
        <a href="#work">Work</a>
        <a href="#contact">Contact</a>

    </div>
</div>

<div id="mobileNav" class="mobile">
    <span href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</span>
    <div class="mobile-content">
        <a href="#home" onclick="closeNav()">Home</a>
        <a href="#about" onclick="closeNav()">About</a>
        <a href="#work" onclick="closeNav()">Work</a>
        <a href="#contact" onclick="closeNav()">Contact</a>
    </div>
</div>

<div class="hamburger">
    <a href="#home"></a>
    <div class="burger">
        <span style="cursor:pointer" onclick="openNav()">&#9776;</span>
    </div>
</div>

<div class="background">
    <div class="info" id="home">
        <a href="https://github.com/PhumlaniDev?tab=repositories" target="_blank"><i class='fab fa-github'></i></a>
        <a href="https://www.linkedin.com/in/phumlani-arendse/" target="_blank"><i class='fab fa-linkedin'></i></a>

        <h1 style="font-size: 65px; color: white;">Phumlani Arendse</h1>
        <p style="color: white; font-size: 18px;">I'm a Web Developer with extensive experience for over 3 years. My
            expertise is to create and design
            websites, Apps, Mockups and many moire</p>
        <button><a href="#contact">CONTACT ME</a></button>
    </div>
</div>

<div class="container">
    <div class="about" id="about">
        <div>
            <img src="../../assets/WhatsApp Image 2021-01-08 at 19.59.01.jpeg" alt="Phumlani Arendse">
        </div>

        <div class="details">
            <h1>About Me.</h1>
            <div class="line"></div>
            <p>I am a self-motivated mobile and back-end developer. I enjoy collaborating with people and adapt well to
                challenges. I am keen to learn new technologies as tech changes everyday. My programming experience has
                offer a lot of knowledge in solving the problems, programming, developing and deploying web applications
                using Github & Git, Heroku with PostgreSQL. I have developed web apps with HTML,CSS and Java.</p>

            <div class="skills">
                <li>HTML</li>
                <li>CSS</li>
                <li>Java</li>
                <li>Android</li>
                <li>Firebase</li>
                <li>PostgreSQL</li>
                <li>Adobe XD</li>
                <li>Bootstrap</li>
            </div>
        </div>
    </div>

    <div class="projects" id="work">
        <h1>Projects</h1>
        <div class="line"></div>
        <div class="work">
            <div>
                <img src="../../assets/gesture machine learning.png" alt="Gesture Machine Learning">

                <a href="http://gesture.herokuapp.com/">
                    <h2>Gesture ML Hackathon</h2>
                </a>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officia similique eaque iure id illum
                    assumenda
                    incidunt, unde, harum eos fugit commodi quibusdam aut nostrum? Dolor numquam modi facere iste autem!
                </p>
            </div>

            <div>
                <img src="../../assets/greet app placeholder.png" alt="Greet app">
                <a href="https://greetwebapp.herokuapp.com/hello">
                    <h2>Greet App</h2>
                </a>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officia similique eaque iure id illum
                    assumenda
                    incidunt, unde, harum eos fugit commodi quibusdam aut nostrum? Dolor numquam modi facere iste autem!
                </p>
            </div>

            <div>
                <img src="../../assets/Employee Manangement System.jpg" alt="Employeee Management System">
                <a href="https://github.com/PhumlaniDev/Employee-Management-Systemme">
                    <h2>Employeee Management System</h2>
                </a>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officia similique eaque iure id illum
                    assumenda
                    incidunt, unde, harum eos fugit commodi quibusdam aut nostrum? Dolor numquam modi facere iste autem!
                </p>
            </div>

            <div>
                <img src="../../assets/Eat Cup Cake.jpg" alt="Eat Cup Cake">
                <a href="https://github.com/PhumlaniDev/EatCupcake">
                    <h2>Eat Cup Cake</h2>
                </a>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officia similique eaque iure id illum
                    assumenda
                    incidunt, unde, harum eos fugit commodi quibusdam aut nostrum? Dolor numquam modi facere iste autem!
                </p>
            </div>

            <div>
                <img src="../../assets/Rock Paper Scicors.png" alt="Rock Paper Scicors">
                <a href="https://github.com/PhumlaniDev/Rock-Paper-Scissors">
                    <h2>Rock Paper Scicors</h2>
                </a>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officia similique eaque iure id illum
                    assumenda
                    incidunt, unde, harum eos fugit commodi quibusdam aut nostrum? Dolor numquam modi facere iste autem!
                </p>
            </div>

            <div>
                <img src="../../assets/hangman.png" alt="hangman">
                <a href="https://github.com/PhumlaniDev/Hangman">
                    <h2>Hangman</h2>
                </a>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officia similique eaque iure id illum
                    assumenda
                    incidunt, unde, harum eos fugit commodi quibusdam aut nostrum? Dolor numquam modi facere iste autem!
                </p>
            </div>

            <div>
                <img src="../../assets/eHealth.jpg" alt="eHealth">
                <a href="https://ehealth-care.herokuapp.com/">
                    <h2>eHealth</h2>
                </a>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officia similique eaque iure id illum
                    assumenda
                    incidunt, unde, harum eos fugit commodi quibusdam aut nostrum? Dolor numquam modi facere iste autem!
                </p>
            </div>
        </div>
    </div>


    <div class="contact" id="contact">
        <h1>Contact Me.</h1>
        <div class="line"></div>
        <form action="https://formspree.io/f/xpzobqzj" method="POST" target="_blank">
            <input type="text" name="Name" placeholder="Name & Surname">
            <input type="text" name="Email" placeholder="Email">
            <input type="text" name="Phone" placeholder="Phone">
            <textarea name="Message" placeholder="Message"></textarea>
            <button type="submit">SEND</button>
        </form>
    </div>

    <footer>
        <p>Designed and Developed by <a href="https://jarod-britz-profile.web.app/">Jarod Britz</a>.</p>
    </footer>
</div>